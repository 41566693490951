<template>
  <div class="ic-section">
    <div class="ic-icon">
      <img
        :src="require(`@/assets/${obj.img.src}`)"
        :height="obj.img.height"
        :width="obj.img.width"
      />
    </div>
    <div class="ic-content" v-html="obj.content"></div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  props: {
    i18n: {
      type: Object,
      default: () => {
        return {
          'icon-content': {
          }
        }
      }
    },
    obj: Object
  },
  computed: {
    image(url) {
      return require(url)
    }
  }
}
</script> 
<style lang="scss" scoped>
.ic-section {
  display: flex;
  margin-bottom: 20px;
  .ic-icon {
    flex: 0 0 auto;
    margin-right: 15px;
  }
  .ic-content {
    flex: 1 1 auto;
  }
}
</style>
