<template>
  <b-card border-variant="secondary" :header="i18n['add-meeting-to-email'].tcAddToCalendar" header-border-variant="secondary" align="center">
    <b-card-text>
      <add-to-calendar
        :title="data.title"
        :location="data.locationString"
        :start="new Date(data.start)"
        :end="new Date(!!data.reccurrencetype? data.reccurrencetype.mtr_recurrence_date_range_end_date : data.end)"
        :details="data.description"
        inline-template
      >
        <div>
          <google-calendar id='google-calendar'>
            <i class='fa fa-google'></i>
            <span id='span-google-calendar'>Google Calendar</span><br />
          </google-calendar>
          <microsoft-calendar id='microsoft-calendar'>
            <i class='fa fa-windows'></i>
            <span id='span-microsoft-calendar'>Microsoft Calendar</span>
          </microsoft-calendar>
        </div>
      </add-to-calendar>
    </b-card-text>
  </b-card>
</template>

<script>
/* eslint-disable */

export default {
  name: 'add-meeting-to-email',
  props: {
    i18n: {
      type: Object,
      default: () => {
        return {
          'add-meeting-to-email': {
            tcAddToCalendar: 'Add to Calendar',
            tcGoogleCalendar: 'Google Calendar',
            tcMicrosoftCalendar: 'Microsoft Calendar',
          },
        }
      },
    },
    meeting: {
      type: Object,
      default: null,
    },
    downloadUrl: {
       type: String,
       default: '',
    }
  },
  data() {
    return {
      data: null,
      downloadRoute: '',
    }
  },
  methods: {
    buildLocationString (location) {
      let locationString = ''
      try {
        const addressElements = []

        const orgName  = location.org_name ? location.org_name : ''
        const adrLine1 = location.adr_line1 ? location.adr_line1 : ''
        const adrLine2 = location.adr_line2 ? location.adr_line2 : ''
        const city     = location.adr_city ? location.adr_city : ''
        const state    = location.adr_state ? location.adr_state : ''
        const zip      = location.adr_postal_code ? location.adr_postal_code : ''

        if (orgName) addressElements.push(orgName.trim())
        addressElements.push(`${adrLine1} ${adrLine2}`.trim())
        addressElements.push(`${city} ${state}, ${zip}`.trim())

        locationString =  addressElements.join(', ')
      } catch (e) {
        console.error('Error in AddMeetingToEmail.vue, buildLocationString()', e)
      } finally {
        if (typeof locationString !== 'string') locationString = ''
        return locationString // we need to return a string, even an empty one
      }
    }
  },
  created() {
    const locationString = this.buildLocationString(this.$props.meeting.location)
    this.data = this.$props.meeting
    this.data.locationString = locationString
    this.downloadRoute = this.$props.downloadUrl
  },
  mounted() {
    document.getElementById('span-google-calendar').innerText = this.i18n['add-meeting-to-email'].tcGoogleCalendar
    document.getElementById('span-microsoft-calendar').innerText = this.i18n['add-meeting-to-email'].tcMicrosoftCalendar
  },
}
</script>

<style>
</style>
